import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"

import { Link } from "gatsby"
import { Container, Row, Col, Image } from "react-bootstrap"
import { Helmet } from "react-helmet"

import Header from "../../components/Header"
import Footer from "../../components/Footer"
import NewsCard from "../../components/NewsCard"
// import MemberCard from "../../components/MemberCard"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"

import { useGlobalState } from "../../global/global"
import { getLanguageFile } from "../../global/langauge.helper"

import DEFAULT_NEWS_IMG from "../../../assets/images/defaultNewsImage.png"
import FSHM_NEWS_IMG from "../../../assets/images/fshmNewsImage.png"
import CUBES from "../../../assets/images/careerDetailCubes.png"
// import ERDAL_CAYIRCI_IMG from "../../../assets/images/erdal-cayirci.png"
// import TULIN_CAYIRCI_IMG from "../../../assets/images/tulin-cayirci.png"
// import TURGAY_KARLIDERE_IMG from "../../../assets/images/turgay-karlidere.png"
// import YAGMUR_TIKTAS_IMG from "../../../assets/images/yagmur-tiktas.png"
// import KAAN_SAYLAM_IMG from "../../../assets/images/kaan-saylam.png"
// import H_MELIS_KILIC_IMG from "../../../assets/images/melis-kilic.png"
import NGINR from "../../../assets/images/nginr-news.jpg"
// import HAMLE from "../../../assets/images/hamleNews.jpeg"
import HAMLE2 from "../../../assets/images/aselsanNews.jpg"
import FIFTHYEAR from "../../../assets/images/5News.jpg"
import ORIENTATION1 from "../../../assets/images/orientation1.jpg"
import HYMOTSG1 from "../../../assets/images/hymotsg1.png"
import NGINR_NEWS_1 from "../../../assets/images/nginrNews1.png"
import HACKATHON_NEWS_1 from "../../../assets/images/hackathonNews.png"
import SUMMER_ACTIVE_NEWS_1 from "../../../assets/images/etkinkli2022.jpg"
import HAVELSAN_NEWS_1 from "../../../assets/images/Havelsan.jpeg"
import CA2X2_NEWS_1 from "../../../assets/images/ca2x2.jpg"
import SAMS_FAT_NEWS from "../../../assets/images/sama_fat.jpeg"
import FOE_NEWS_1 from "../../../assets/images/foe_news_1.jpg"
import FOE_NEWS_2 from "../../../assets/images/foe_news_2.jpg"
import ORIENTATION2 from "../../../assets/images/orientation2.jpg"
import HAVELSAN_TSK_NEWS_1 from "../../../assets/images/havelsan_tsk_news.png"
import HYMOTS_CBRN from "../../../assets/images/CBRN_HYMOTS_TEAM.jpeg"
import MULTI_LEVEL from "../../../assets/images/multi-level-joint.jpeg"
import TECHNICAL_GROUP from "../../../assets/images/NATO-Technical-Group.jpeg"
import QATAR_TEAM from "../../../assets/images/QatarTeam.jpeg"
import BURAK_QATAR from "../../../assets/images/mr_Burak_at_Qatar.jpeg"
import ITSEC from "../../../assets/images/ITSEC.jpeg"
import NEW_COURSE from "../../../assets/images/NEW_COURSE.jpeg"
import IITSEC from "../../../assets/images/ITSEC_2024.jpeg"

import "./styles.scss"

const AboutUsPage = () => {
  const [state] = useGlobalState()

  const sliderSettings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1450,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  return (
    <Container fluid className="main-container">
      <div className="application">
        <Helmet title={getLanguageFile(state.language).Title.aboutUs}>
          <meta charSet="utf-8" />
        </Helmet>
      </div>
      <Header />
      <Container fluid className="about-us-image-container">
        <Image className={"about-us-image-background"} src={CUBES} />
      </Container>
      <Container
        fluid
        className="about-us-first-section d-flex align-items-center"
      >
        <Container className="about-us-introduction-container">
          <Row>
            <Col className={"about-us-introduction-texts-col col-lg-8 col-10"}>
              <Row>
                <h2 className="about-us-ceo-description-title">
                  {getLanguageFile(state.language).AboutUs.ceoDescriptionTitle}
                </h2>
              </Row>
              <Row>
                <p className="about-us-ceo-description">
                  {getLanguageFile(state.language).AboutUs.ceoDescription}
                </p>
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>

      {/* <Container fluid className="about-us-team-container">
        <div className="team-title-container">
          <p className="team-title">
            {getLanguageFile(state.language).AboutUs.team}
          </p>
        </div>

        <h2 className="team-description">
          {getLanguageFile(state.language).AboutUs.teamDescription}
        </h2>
        <Row>
          <Col className="col-lg-4 offset-lg-3 col-md-5 offset-md-1 col-12 mt-4">
            <MemberCard
              memberImage={ERDAL_CAYIRCI_IMG}
              memberNameSurname="Erdal Çayırcı"
              memberRole={getLanguageFile(state.language).AboutUs.ceo}
            />
          </Col>
          <Col className="col-lg-5 col-md-6 col-12  mt-4">
            <MemberCard
              memberImage={TULIN_CAYIRCI_IMG}
              memberNameSurname="Tülin Çayırcı"
              memberRole={getLanguageFile(state.language).AboutUs.coo}
            />
          </Col>
        </Row>
        <Row>
          <Col className="col-lg-4 offset-lg-3 col-md-5 offset-md-1 col-12 mt-4">
            <MemberCard
              memberImage={TURGAY_KARLIDERE_IMG}
              memberNameSurname="Turgay Karlıdere"
              memberRole={
                getLanguageFile(state.language).AboutUs.chiefTechnologyOfficer
              }
            />
          </Col>
          <Col className="col-lg-5 col-md-6 col-12  mt-4">
            <MemberCard
              memberImage={H_MELIS_KILIC_IMG}
              memberNameSurname="H.Melis Kılıç"
              memberRole={getLanguageFile(state.language).AboutUs.msTeamLeader}
            />
          </Col>
        </Row>
        <Row>
          <Col className="col-lg-4 offset-lg-3 col-md-5 offset-md-1 col-12 mt-4">
            <MemberCard
              memberImage={KAAN_SAYLAM_IMG}
              memberNameSurname="Kaan Saylam"
              memberRole={getLanguageFile(state.language).AboutUs.hmiTeamLeader}
            />
          </Col>
          <Col className="col-lg-5 col-md-6 col-12  mt-4">
            <MemberCard
              memberImage={YAGMUR_TIKTAS_IMG}
              memberNameSurname="Yağmur Tiktaş"
              memberRole={
                getLanguageFile(state.language).AboutUs.humanResources
              }
            />
          </Col>
        </Row>
      </Container> */}

      <Container fluid className="news-container">
        <h2 className="news-heading-text">
          {getLanguageFile(state.language).AboutUs.news}
        </h2>

        <Slider style={{ width: "70%" }} {...sliderSettings}>
          <NewsCard
            newsImg={NEW_COURSE}
            goTo="https://www.linkedin.com/posts/etetechnology_the-new-edition-of-our-five-month-long-course-activity-7271787396369633280-MPvH?utm_source=share&utm_medium=member_desktop"
            newsDescription={
              getLanguageFile(state.language).AboutUs.newCourse
            }
          />
          <NewsCard
            newsImg={IITSEC}
            goTo="https://www.linkedin.com/feed/update/urn:li:activity:7269647528675979264"
            newsDescription={
              getLanguageFile(state.language).AboutUs.IITSEC
            }
          />
          <NewsCard
            newsImg={ITSEC}
            goTo="https://www.linkedin.com/posts/etetechnology_during-iitsec-please-visit-nato-booth-to-activity-7267140652130324482-I3bp?utm_source=share&utm_medium=member_desktop"
            newsDescription={
              getLanguageFile(state.language).AboutUs.itsec
            }
          />
          <NewsCard
            newsImg={BURAK_QATAR}
            goTo="https://www.linkedin.com/posts/etetechnology_this-week-our-junior-leadership-programme-activity-7266727587240955904-tYsN?utm_source=share&utm_medium=member_desktop"
            newsDescription={
              getLanguageFile(state.language).AboutUs.jrLeadershipProgram
            }
          />
          <NewsCard
            newsImg={QATAR_TEAM}
            goTo="https://www.linkedin.com/feed/update/urn:li:activity:7265264431268331520"
            newsDescription={
              getLanguageFile(state.language).AboutUs.ourTeamStartsEnjoying
            }
          />
          <NewsCard
            newsImg={MULTI_LEVEL}
            goTo="https://www.linkedin.com/posts/etetechnology_this-week-our-team-supports-a-multi-level-activity-7264159889369780224-Bjaf?utm_source=share&utm_medium=member_desktop"
            newsDescription={
              getLanguageFile(state.language).AboutUs.multiLevelJoint
            }
          />
          <NewsCard
            newsImg={TECHNICAL_GROUP}
            goTo="https://www.linkedin.com/feed/update/urn:li:activity:7263082618949173248"
            newsDescription={
              getLanguageFile(state.language).AboutUs.natoTechnicalGroup
            }
          />
          <NewsCard
            newsImg={HYMOTS_CBRN}
            goTo="https://www.linkedin.com/posts/etetechnology_our-team-starts-supporting-a-geostrategic-activity-7257277130844094464-wUIz?utm_source=share&utm_medium=member_desktop"
            newsDescription={getLanguageFile(state.language).AboutUs.hymotsCBRN}
          />
          <NewsCard
            newsImg={HAVELSAN_TSK_NEWS_1}
            goTo="https://tr.linkedin.com/posts/etetechnology_havelsan-hymots-etecube-activity-7201566608408907776-Xyfw?utm_source=li_share&utm_content=feedcontent&utm_medium=g_dt_web&utm_campaign=copy"
            newsDescription={
              getLanguageFile(state.language).AboutUs.havelsanTskNews1
            }
          />
          <NewsCard
            newsImg={ORIENTATION2}
            goTo="https://www.linkedin.com/posts/etetechnology_y%C3%B6netim-akademisi-e%C4%9Fitim-s%C3%BCrecinin-%C3%B6nemli-activity-7191744928421441537-XQVP?utm_source=share&utm_medium=member_desktop"
            newsDescription={
              getLanguageFile(state.language).AboutUs.orientation2
            }
          />
          <NewsCard
            newsImg={FOE_NEWS_2}
            goTo="https://www.linkedin.com/feed/update/urn:li:activity:7179076866090123264"
            newsDescription={getLanguageFile(state.language).AboutUs.foeNews2}
          ></NewsCard>
          <NewsCard
            newsImg={FOE_NEWS_1}
            goTo="https://www.linkedin.com/feed/update/urn:li:activity:7179394478812655616"
            newsDescription={getLanguageFile(state.language).AboutUs.foeNews1}
          ></NewsCard>
          <NewsCard
            newsImg={SAMS_FAT_NEWS}
            newsDescription={
              getLanguageFile(state.language).AboutUs.samaFatNews
            }
          ></NewsCard>
          <NewsCard
            newsImg={CA2X2_NEWS_1}
            goTo="https://www.linkedin.com/feed/update/urn:li:activity:7117471203153330177"
            newsDescription={getLanguageFile(state.language).AboutUs.ca2x2News1}
          ></NewsCard>
          <NewsCard
            newsImg={HAVELSAN_NEWS_1}
            goTo="https://tr.linkedin.com/posts/etetechnology_idef2023-esnas%C4%B1nda-havelsan-a%C5%9F-ile-i%C5%9Fbirli%C4%9Fi-activity-7089959190827327489-QVs4?utm_source=li_share&utm_content=feedcontent&utm_medium=g_dt_web&utm_campaign=copy"
            newsDescription={
              getLanguageFile(state.language).AboutUs.havelsanNews1
            }
          ></NewsCard>
          <NewsCard
            newsImg={SUMMER_ACTIVE_NEWS_1}
            goTo="https://www.linkedin.com/posts/etesolutions_etkinlik2022-activity-6955482265841733632-xvP2/"
            newsDescription={
              getLanguageFile(state.language).AboutUs.summerActiveNews1
            }
          ></NewsCard>
          <NewsCard
            newsImg={HACKATHON_NEWS_1}
            goTo="https://www.linkedin.com/posts/etesolutions_hackathon-teknoparkiaovzmir-hack4treat-activity-6864824426647691265-8nxw"
            newsDescription={
              getLanguageFile(state.language).AboutUs.hackathonNews1
            }
          ></NewsCard>
          <NewsCard
            newsImg={NGINR_NEWS_1}
            goTo="https://www.linkedin.com/posts/etesolutions_teknoparkizmir-etecube-nginr-activity-6845987204033069056-slVp"
            newsDescription={getLanguageFile(state.language).AboutUs.nginrNews1}
          ></NewsCard>
          <NewsCard
            newsImg={HYMOTSG1}
            goTo="https://www.linkedin.com/posts/etesolutions_hymotsg-kullan%C4%B1c%C4%B1-ile-bulu%C5%9Ftu-2018-y%C4%B1l%C4%B1nda-activity-6820692796408786944-RVAy"
            newsDescription={getLanguageFile(state.language).AboutUs.hymotsg1}
          ></NewsCard>
          <NewsCard
            newsImg={ORIENTATION1}
            goTo="https://www.linkedin.com/posts/etesolutions_y%C4%B1l%C4%B1n-ilk-e%C4%9Fitim-toplant%C4%B1s%C4%B1n%C4%B1-tamamlad%C4%B1k-activity-6778566722329882624-O0mV"
            newsDescription={
              getLanguageFile(state.language).AboutUs.orientation1
            }
          />
          <NewsCard
            newsImg={FIFTHYEAR}
            goTo="https://www.linkedin.com/posts/etesolutions_%C5%9Firketimizin-5-kurulu%C5%9F-y%C4%B1ld%C3%B6n%C3%BCm%C3%BCnde-%C3%A7al%C4%B1%C5%9Fanlar%C4%B1m%C4%B1z%C4%B1-activity-6772083597345206272-skBG"
            newsDescription={getLanguageFile(state.language).AboutUs.fifthYear}
          />
          <NewsCard
            newsImg={HAMLE2}
            goTo="https://www.linkedin.com/posts/etesolutions_savunma-sanayii-ba%C5%9Fkanl%C4%B1%C4%9F%C4%B1-b%C3%BCnyesinde-ger%C3%A7ekle%C5%9Ftirilen-activity-6770987269366132736-r634"
            newsDescription={getLanguageFile(state.language).AboutUs.hamleNew2}
          />
          <NewsCard
            newsImg={DEFAULT_NEWS_IMG}
            goTo="https://www.linkedin.com/posts/etesolutions_savunma-sanayii-ba%C5%9Fkanl%C4%B1%C4%9F%C4%B1-b%C3%BCnyesinde-ger%C3%A7ekle%C5%9Ftirilen-activity-6763017401085886464-u1cZ"
            newsDescription={getLanguageFile(state.language).AboutUs.hamleNew}
          />
          <NewsCard
            newsImg={NGINR}
            goTo="https://www.linkedin.com/posts/etesolutions_activity-6757990102061998080-SUKS/"
            newsDescription={getLanguageFile(state.language).AboutUs.nginrNew}
          />
          <NewsCard
            newsImg={FSHM_NEWS_IMG}
            goTo="https://www.linkedin.com/posts/etesolutions_teknoparkizmir-etecube-teknopark-activity-6734357816926081024-ycH5"
            newsDescription={getLanguageFile(state.language).AboutUs.fourthNew}
          />
          <NewsCard
            newsImg={DEFAULT_NEWS_IMG}
            goTo="https://www.linkedin.com/posts/etesolutions_nisan-ay%C4%B1-sonu-itibar%C4%B1yla-melez-%C3%A7ok-alanl%C4%B1-activity-6663687560843284481-nPqB"
            newsDescription={getLanguageFile(state.language).AboutUs.firstNew}
          />
          <NewsCard
            newsImg={DEFAULT_NEWS_IMG}
            goTo="https://www.linkedin.com/posts/etesolutions_savunma-sanayi-ba%C5%9Fkanl%C4%B1%C4%9F%C4%B1n%C4%B1n-2020-y%C4%B1l%C4%B1-i%C3%A7in-activity-6663686647768457217-lQjb"
            newsDescription={getLanguageFile(state.language).AboutUs.thirdNew}
          />
        </Slider>
        <Link to={"https://www.linkedin.com/company/etesolutions/"}>
          <h2 className="view-all-text">
            {getLanguageFile(state.language).AboutUs.viewAllNews}
          </h2>
        </Link>
      </Container>
      <Footer extraStyle={{ zIndex: 9999, backgroundColor: "white" }} />
    </Container>
  )
}

export default AboutUsPage
