import PropTypes from "prop-types"
import React from "react"
import "./styles.scss"
import { Link } from "gatsby"
import { Col, Container, Image } from "react-bootstrap"
import { useGlobalState } from "../../global/global"
import { getLanguageFile } from "../../global/langauge.helper"

const NewsCard = ({ newsImg, newsDate, newsDescription, goTo }) => {
  const [state] = useGlobalState()

  return (
    <Col className="cards-col">
      <Container className="card">
        <Image className="card-img" src={newsImg} />
        <p className="card-text">
          {newsDate}
          <br />
          {newsDescription}
        </p>
        {goTo !== "" && (
          <Link to={goTo} target="_blank">
            <h4 className={"read-more-text"}>
              {getLanguageFile(state.language).NewsCard.readMore}
            </h4>
          </Link>
        )}
      </Container>
    </Col>
  )
}

NewsCard.propTypes = {
  newsImg: PropTypes.any,
  newsDate: PropTypes.string,
  newsDescription: PropTypes.string,
  goTo: PropTypes.string,
}

NewsCard.defaultProps = {
  newsDate: ``,
  newsDescription: ``,
  goTo: ``,
}

export default NewsCard
